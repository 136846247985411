function getURL(api) {
  
  const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;

  switch(api) {
    case "LOGIN":
      return `${BASE_URL}/login`;

    case "LOGOUT":
      return `${BASE_URL}/logout`;
    
    case "GET_USERS":
      return `${BASE_URL}/users`;

    case "GET_USER":
      return (id) => `${BASE_URL}/users/${id}`;

    case "INSERT_USER":
      return `${BASE_URL}/users`;
    
    case "UPDATE_USER":
      return (id) => `${BASE_URL}/users/${id}`;

    case "DELETE_USER":
      return (id) => `${BASE_URL}/users/${id}`;
    
    case "GET_VOTERS":
      return `${BASE_URL}/voters`;

    case "GET_VOTER":
      return (id) => `${BASE_URL}/voters/${id}`;

    case "INSERT_VOTER":
      return `${BASE_URL}/voters`;
    
    case "UPDATE_VOTER":
      return (id) => `${BASE_URL}/voters/${id}`;

    case "DELETE_VOTER":
      return (id) => `${BASE_URL}/voters/${id}`;      

    case "SEND_REQUEST_FOR_DEMO_EMAIL":
      return `${BASE_URL}/send-request-for-demo-email`;

    case "GET_VARIABLE_LIST":
      return `${BASE_URL}/variables`;

    case "GET_VARIABLE":
      return (id) => `${BASE_URL}/variables/${id}`;

    case "INSERT_VARIABLE":
      return `${BASE_URL}/variables`;

    case "UPDATE_VARIABLE":
      return (id) => `${BASE_URL}/variables/${id}`;

    case "DELETE_VARIABLE":
      return (id) => `${BASE_URL}/variables/${id}`;

    case "UPLOAD_VOTERS":
      return `${BASE_URL}/import-voters`;

    case "UPLOAD_USERS":
      return `${BASE_URL}/import-users`;
    
    case "GET_PROVINCE_LIST":
      return `${BASE_URL}/provinces`;

    case "GET_PROVINCE":
      return (id) => `${BASE_URL}/provinces/${id}`;

    case "INSERT_PROVINCE":
      return `${BASE_URL}/provinces`;

    case "UPDATE_PROVINCE":
      return (id) => `${BASE_URL}/provinces/${id}`; 

    case "DELETE_PROVINCE":
      return (id) => `${BASE_URL}/provinces/${id}`;
    
    case "GET_MUNICIPALITY_LIST":
      return `${BASE_URL}/citiesmunicipalities`;
    
    case "GET_MUNICIPALITY":
      return (id) => `${BASE_URL}/citiesmunicipalities/${id}`;

    case "INSERT_MUNICIPALITY":
      return `${BASE_URL}/citiesmunicipalities`;

    case "UPDATE_MUNICIPALITY":
      return (id) => `${BASE_URL}/citiesmunicipalities/${id}`;  

    case "DELETE_MUNICIPALITY":
      return (id) => `${BASE_URL}/citiesmunicipalities/${id}`;

    case "FORGOT_PASSWORD":
      return `${BASE_URL}/forgot-password`;

    case "FILTER_VOTER_LIST":
      return `${BASE_URL}/filter-voters`;

    case "GET_ROLE_LIST":
      return `${BASE_URL}/user-roles`;

    case "ADD_VARIABLE_OPTION":
      return `${BASE_URL}/variable-options`;

    case "UPDATE_VARIABLE_OPTION":
      return (id) => `${BASE_URL}/variable-options/${id}`;

    case "ADD_ROLE":
      return `${BASE_URL}/user-roles`;

    case "UPDATE_ROLE":
      return (id) => `${BASE_URL}/user-roles/${id}`;

    case "GET_PRECINCT_LIST":
      return `${BASE_URL}/precincts`;

    case "GET_BARANGAY_LIST":
      return `${BASE_URL}/barangays`;

    case "GET_PROVINCES":
      return `${BASE_URL}/provinces`;

    case "GET_DISTRICTS":
      return `${BASE_URL}/districts`;

    case "GET_MUNI_CITIES":
      return `${BASE_URL}/citiesmunicipalities`;

    case "GET_BARANGAYS":
      return `${BASE_URL}/barangays`;

    case "GET_PRECINCTS":
      return `${BASE_URL}/precincts`;
    
    case "GET_VOTER_DISTRIBUTION":
      return `${BASE_URL}/voter-distribution`;

    case 'GET_KPIS':
    case 'INSERT_KPI':
      return `${BASE_URL}/kpi`;

    case 'GET_KPI':
    case 'UPDATE_KPI':
    case 'DELETE_KPI':
      return (id) => `${BASE_URL}/kpi/${id}`

    default:
      return BASE_URL;
  }
};

export default getURL;