import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Stack, Spinner, Container } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { getVoter } from "actions/voterActions.js";
import { variableSelector } from "selectors/variableSelector";
import { formatDate } from "helpers";
import { v4 as uuidv4 } from "uuid";
import { voterSelector } from "selectors/voterSelector";
import { useGeopoliticalUnits } from "hooks/useGeopoliticalUnits";

function VoterModal(props) {
    const { onHide, show, voterId } = props;
    const [formData, setFormData] = useState({});
    const { barangays, muniCities, precincts, provinces, districts } = useGeopoliticalUnits({
        province: formData.province,
        district: formData.district,
        muniCity: formData.municity,
        barangay: formData.barangay,
        precinct: formData.precinct
    });
    const { variables } = useSelector(variableSelector);
    const { isLoading, voter } = useSelector(voterSelector);
    const dispatch = useDispatch();

    const getInputType = (variable) => {
        switch (variable.type) {
            case 'ARRAY':
                return <Form.Select name={variable.name} onChange={handleVoterResponseChange} />
            default:
                return <Form.Control name={variable.name} onChange={handleVoterResponseChange} />
        }
    }

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    };

    const handleClose = () => {
        onHide();
    }

    const handleSubmit = async (event) => {
        console.log('Filters submitted');
    };

    const handleVoterResponseChange = (e) => {
        console.log('Response changed');
    };

    useEffect(() => {
        dispatch(getVoter(voterId));
    }, [dispatch, voterId]);

    useEffect(() => {
        if (voter) {
            setFormData({ ...voter, voter_responses: JSON.parse(voter.voter_responses[0]?.response || '{}') });
        }
    }, [voter]);

    return (
        <Modal onHide={handleClose} show={show} size="lg" animation>
            <Modal.Header closeButton>Voter Profile</Modal.Header>
            <Modal.Body>
                <Container>
                    <Form onSubmit={handleSubmit}>
                        <Stack gap={2} className="mb-3">
                            <Row>
                                <Col lg={3}><Form.Label>Full Name</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="name"
                                        onChange={handleChange}
                                        defaultValue={formData.name}
                                        required
                                        disabled={voterId}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a name.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Address</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="address"
                                        onChange={handleChange}
                                        defaultValue={formData.address}
                                        required
                                        disabled={voterId}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide an address.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Sex</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Select
                                        id="sex"
                                        name="sex"
                                        onChange={handleChange}
                                        value={formData.sex}
                                        required
                                        disabled={voterId}
                                    >
                                        <option value="">Select sex</option>
                                        {['M', 'F'].map((value) => (
                                            <option key={uuidv4()} value={value}>
                                                {value === 'M' ? 'Male' : 'Female'}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a sex.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Birthdate</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Control
                                        id="birthdate"
                                        name="birthdate"
                                        type="date"
                                        onChange={handleChange}
                                        defaultValue={formatDate(formData.birthdate)}
                                        required
                                        disabled={voterId}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a birthdate.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Province</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Select
                                        name="province"
                                        onChange={handleChange}
                                        value={formData.province}
                                        required
                                        disabled={voterId}
                                    >
                                        <option value="">Select province</option>
                                        {provinces.map((province) => (
                                            <option key={uuidv4()} value={province}>
                                                {province}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a province.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>District</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Select
                                        name="province"
                                        onChange={handleChange}
                                        value={formData.district}
                                        required
                                        disabled={voterId}
                                    >
                                        <option value="">Select province</option>
                                        {districts.map((district) => (
                                            <option key={uuidv4()} value={district}>
                                                {district}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a district.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Municipality</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Select
                                        name="municipality"
                                        onChange={handleChange}
                                        value={formData.municity}
                                        required
                                        disabled={voterId}
                                    >
                                        <option value="">Select municipality</option>
                                        {muniCities.map((municipality) => (
                                            <option key={uuidv4()} value={municipality}>
                                                {municipality}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a municipality.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Barangay</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Select
                                        name="barangay"
                                        onChange={handleChange}
                                        value={formData.barangay}
                                        required
                                        disabled={voterId}
                                    >
                                        <option value="">Select barangay</option>
                                        {
                                            barangays.map((barangay) => (
                                                <option key={uuidv4()} value={barangay}>{barangay}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a barangay.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={3}><Form.Label>Precinct</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Select
                                        name="precinct"
                                        onChange={handleChange}
                                        value={formData.precinct}
                                        required
                                        disabled={voterId}
                                    >
                                        <option value="">Select precinct</option>
                                        {
                                            precincts.map((precinct) => (
                                                <option key={uuidv4()} value={precinct}>{precinct}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a precinct.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            {
                                variables.map((variable) => (
                                    <Row key={uuidv4()}>
                                        <Col lg={3}><Form.Label>{variable.name}</Form.Label></Col>
                                        <Col lg={9}>{getInputType(variable)}</Col>
                                    </Row>
                                ))
                            }
                        </Stack>
                        <Modal.Footer>
                            <Button variant="success" type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        <span>Saving...</span>
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default VoterModal