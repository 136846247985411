import { 
    ADD_PROVINCE_FAILED, 
    ADD_PROVINCE_SUCCESS, 
    DELETE_PROVINCE, 
    SHOW_PROVINCE, 
    UPDATE_PROVINCE_FAILED, 
    UPDATE_PROVINCE_SUCCESS, 
    PROVINCE_LIST_LOAD_FAILED, 
    PROVINCE_LIST_LOAD_SUCCESS 
} from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    provinces: [],
    province: null,
    total: 0
})

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case PROVINCE_LIST_LOAD_SUCCESS:
            return {
                ...state,
                provinces: action.payload.provinces,
                total: action.payload.total,
                error: null
            };
        case PROVINCE_LIST_LOAD_FAILED:
            return {
                ...state,
                provinces: [],
                error: action.payload.errors
            }
        case ADD_PROVINCE_SUCCESS:
            return {
                ...state,
                province: action.payload.province,
                error: null
            };
        case ADD_PROVINCE_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case SHOW_PROVINCE:
            return {
                ...state,
                province: action.payload.province,
                error: action.error ? action.payload.errors : null
            };
        case UPDATE_PROVINCE_SUCCESS:
            return {
                ...state,
                province: action.payload.province,
                error: null
            };
        case UPDATE_PROVINCE_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case DELETE_PROVINCE:
            return {
                ...state,
                province: action.payload.province,
                error: action.error ? action.payload.errors : null
            };
        default:
            return state;
    }
}