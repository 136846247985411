import getURL from "api/config";
import { GET, PUT, POST, DELETE } from "api";
import {
  GET_KPIS_SUCCESS, GET_KPIS_FAILED, GET_KPIS_REQUEST,
  GET_KPI_SUCCESS, GET_KPI_FAILED, GET_KPI_REQUEST,
  ADD_KPI_SUCCESS, ADD_KPI_FAILED, ADD_KPI_REQUEST,
  UPDATE_KPI_SUCCESS, UPDATE_KPI_FAILED, UPDATE_KPI_REQUEST,
  DELETE_KPI_SUCCESS, DELETE_KPI_FAILED, DELETE_KPI_REQUEST
} from "constants/actionTypes";
import { getError } from "helpers";

export function getKpisAction(data) {
  return function action(dispatch) {
    const url = getURL("GET_KPIS");
    const request = GET(url, data);

    dispatch({ type: GET_KPIS_REQUEST })

    return request.then((response) => {
      const kpis = response?.data?.result || [];
      const total = response?.data?.result?.data?.total || 0;

      dispatch({ type: GET_KPIS_SUCCESS, payload: { kpis, total }})
    }, (error) => {
      dispatch({ type: GET_KPIS_FAILED, payload: { errors: getError(error) } })
    })
  };
}

export function getKpiAction(id) {
  return function action(dispatch) {
    const url = getURL("GET_KPI")(id);
    const request = GET(url);

    dispatch({ type: GET_KPI_REQUEST });

    return request.then((response) => {
      dispatch({ type: GET_KPI_SUCCESS, payload: { kpi: response.data.result } });
    }, (error) => {
      dispatch({ type: GET_KPI_FAILED, payload: { errors: getError(error) } });
    });
  };
}

export function addKpiAction(data) {
  return function action(dispatch) {
    const url = getURL("INSERT_KPI");
    const request = POST(url, data);

    dispatch({ type: ADD_KPI_REQUEST });

    return request.then(
      (response) => {
        dispatch({ type: ADD_KPI_SUCCESS, payload: { kpi: response.data.result } });
      },
      (error) => {
        dispatch({ type: ADD_KPI_FAILED, payload: { errors: getError(error) } });
      }
    );
  };
}

export function updateKpiAction(id, data) {
  return function action(dispatch) {
    const url = getURL("UPDATE_KPI")(id);
    const request = PUT(url, data);

    dispatch({ type: UPDATE_KPI_REQUEST });

    return request.then(
      (response) => {
        dispatch({ type: UPDATE_KPI_SUCCESS, payload: { kpi: response.data.result } });
      },
      (error) => {
        dispatch({ type: UPDATE_KPI_FAILED, payload: { errors: getError(error) } });
      }
    );
  };
}

export function deleteKpiAction(id) {
  return function action(dispatch) {
    const url = getURL("DELETE_KPI")(id);
    const request = DELETE(url);

    dispatch({ type: DELETE_KPI_REQUEST });

    return request.then(
      (response) => {
        dispatch({ type: DELETE_KPI_SUCCESS, payload: { kpiId: id } });
      },
      (error) => {
        dispatch({ type: DELETE_KPI_FAILED, payload: { errors: getError(error) } });
      }
    );
  };
}