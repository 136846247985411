import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { clearUserErrorsAction, updateUserAction, addUserAction } from "actions/userActions.js";
import { userSelector } from "selectors/userSelector";
import { useSelector } from "react-redux";
import AlertMessage from "components/AlertMessage/AlertMessage";
import { formatDate } from "helpers";
import { getRoleListAction } from "actions/roleActions";
import { roleSelector } from "selectors/roleSelector";
import { precinctSelector } from "selectors/precinctSelector";
import { barangaySelector } from "selectors/barangaySelector";
import { municipalitySelector } from "selectors/municipalitySelector";
import { provinceSelector } from "selectors/provinceSelector";
import { getPrecinctListAction } from "actions/precinctActions";
import { getBarangayListAction } from "actions/barangayActions";
import { getMunicipalityListAction } from "actions/municipalityActions";
import { getProvinceListAction } from "actions/provinceActions";

function UserModal(props) {
    const { roles } = useSelector(roleSelector);
    const { precincts } = useSelector(precinctSelector);
    const { barangays } = useSelector(barangaySelector);
    const { municipalities } = useSelector(municipalitySelector);
    const { provinces } = useSelector(provinceSelector);
    const { error } = useSelector(userSelector);
    const { user, onHide } = props;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(user);

    const handleClose = () => {
        dispatch(clearUserErrorsAction());
        onHide();
    }

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    };

    const handleSubmit = () => {
        console.log(formData);
        if (user.user_id) {
            dispatch(updateUserAction(formData.user_id, formData));
        } else {
            dispatch(addUserAction(formData));
        }

        handleClose();
    };


    useEffect(() => {
        dispatch(getRoleListAction());
        dispatch(getPrecinctListAction());
        dispatch(getBarangayListAction());
        dispatch(getMunicipalityListAction());
        dispatch(getProvinceListAction());
    }, [dispatch]);

    return (
        <Modal onHide={handleClose} show={true} size="lg">
            {error && <AlertMessage heading="Add User Failed" message={error} variant="danger" dismissible />}
            <Modal.Header closeButton>User Profile</Modal.Header>
            <Modal.Body>
                <Form>
                    <Stack gap={2}>
                        <h4>Personal Information</h4>
                        <Row>
                            <Col lg={3}><Form.Label>Name</Form.Label></Col>
                            <Col lg={9}><Form.Control defaultValue={formData.name} name="name" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Sex</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="sex" defaultValue={formData.sex} onChange={handleChange}>
                                    <option value="">Select</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Birthdate</Form.Label></Col>
                            <Col lg={9}><Form.Control type="date" defaultValue={formatDate(formData.birthdate)} name="birthdate" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Contact No</Form.Label></Col>
                            <Col lg={9}><Form.Control defaultValue={formData.contact_no} name="contact_no" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Email</Form.Label></Col>
                            <Col lg={9}><Form.Control defaultValue={formData.email} name="email" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Username</Form.Label></Col>
                            <Col lg={9}><Form.Control defaultValue={formData.username} name="username" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Role</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="role" defaultValue={formData.role} onChange={handleChange}>
                                    {
                                        roles.map((role) => (
                                            <option key={role.user_role_id} value={role.user_role_id}>{role.user_role_name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <h4>Assignments</h4>
                        <Row>
                            <Col lg={3}><Form.Label>Precinct</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="precinct" value={formData.precinct} onChange={handleChange}>
                                    <option value="">All</option>
                                    {
                                        precincts.map((precinct) => (
                                            <option key={precinct} value={precinct}>{precinct}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Barangay</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="barangay" value={formData.barangay} onChange={handleChange}>
                                    <option value="">All</option>
                                    {
                                        barangays.map((barangay) => (
                                            <option key={barangay} value={barangay}>{barangay}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Municipality/City</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="municity" value={formData.municity} onChange={handleChange}>
                                    <option value="">All</option>
                                    {
                                        municipalities.map((municipality) => (
                                            <option key={municipality} value={municipality}>{municipality}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Province</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="province" value={formData.province} onChange={handleChange}>
                                    <option value="">All</option>
                                    {
                                        provinces.map((province) => (
                                            <option key={province} value={province}>{province}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger">Reset Password</Button>{" "}
                <Button variant="success" onClick={() => handleSubmit()}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UserModal