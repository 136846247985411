import { 
    ADD_MUNICIPALITY_FAILED, 
    ADD_MUNICIPALITY_SUCCESS, 
    DELETE_MUNICIPALITY, 
    SHOW_MUNICIPALITY, 
    UPDATE_MUNICIPALITY_FAILED, 
    UPDATE_MUNICIPALITY_SUCCESS, 
    MUNICIPALITY_LIST_LOAD_FAILED, 
    MUNICIPALITY_LIST_LOAD_SUCCESS 
} from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    municipalities: [],
    municipality: null,
    total: 0
})

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case MUNICIPALITY_LIST_LOAD_SUCCESS:
            return {
                ...state,
                municipalities: action.payload.municipalities,
                total: action.payload.total,
                error: null
            };
        case MUNICIPALITY_LIST_LOAD_FAILED:
            return {
                ...state,
                municipalities: [],
                error: action.payload.errors
            }
        case ADD_MUNICIPALITY_SUCCESS:
            return {
                ...state,
                municipality: action.payload.municipality,
                error: null
            };
        case ADD_MUNICIPALITY_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case SHOW_MUNICIPALITY:
            return {
                ...state,
                municipality: action.payload.municipality,
                error: action.error ? action.payload.errors : null
            };
        case UPDATE_MUNICIPALITY_SUCCESS:
            return {
                ...state,
                municipality: action.payload.municipality,
                error: null
            };
        case UPDATE_MUNICIPALITY_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case DELETE_MUNICIPALITY:
            return {
                ...state,
                municipality: action.payload.municipality,
                error: action.error ? action.payload.errors : null
            };
        default:
            return state;
    }
}