import "./Landing.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useEffect, useCallback } from "react";
import { MODULE_LIST } from "constants/common";
import { useSelector } from "react-redux";
import { sidebarSelector } from "selectors/sidebarSelector";

function Landing() {
    const location = useLocation();
    const path = location.pathname;
    const { showSidebar } = useSelector(sidebarSelector);

    const handleAccess = useCallback(() => {
       const bitValue = localStorage.getItem("bitValue");
       const bitIndex = MODULE_LIST.findIndex((module) => path.includes(module));
       if (bitValue[bitIndex] === '0') {
            window.location.href = '/cms';
        }
    }, [path]);

    useEffect(() => {
        handleAccess();
    }, [handleAccess]);

    return (
        <div className="d-flex justify-content-between">
            {showSidebar && <Col className="landing-sidebar" xs={2}><Sidebar /></Col>}
            <Col className="landing-content" xs={showSidebar ? 10 : 12}><Outlet /></Col>
        </div>
    )
}

export default Landing;