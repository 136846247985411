import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getVoters
} from 'actions/voterActions';
import { voterSelector } from 'selectors/voterSelector';

export function useVoters(filters) {
  const dispatch = useDispatch();
  const {
    isLoading,
    total,
    voters,
    votersColumns,
    votersCurrentPage,
    votersLastPage,
    votersError
  } = useSelector(voterSelector);

  useEffect(() => {
    dispatch(getVoters(filters));
  }, [dispatch, filters]);

  return {
    isLoading,
    total,
    voters,
    votersColumns,
    votersCurrentPage,
    votersLastPage,
    votersError
  };
};