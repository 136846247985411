import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProvinces,
  getDistricts,
  getMuniCities,
  getBarangays,
  getPrecincts
} from 'actions/geopoliticalUnitActions';
import { geoPoliticalUnitSelector } from 'selectors/geopoliticalUnitSelector';

export function usePlaces({ unit_level }) {
  const dispatch = useDispatch();
  const {
    provinces,
    districts,
    muniCities,
    barangays,
    precincts
  } = useSelector(geoPoliticalUnitSelector);

  useEffect(() => {
    if (!unit_level) return;

    const fetchActions = {
      province: getProvinces,
      district: getDistricts,
      municity: getMuniCities,
      barangay: getBarangays,
      precinct: getPrecincts
    }

    const action = fetchActions[unit_level];
    if (action) {
      dispatch(action());
    }

  }, [unit_level, dispatch]);

  const places = useMemo(() => {
    const placeMap = {
      province: provinces,
      district: districts,
      municity: muniCities,
      barangay: barangays,
      precinct: precincts
    };

    return placeMap[unit_level] || [];
  }, [unit_level, provinces, districts, muniCities, barangays, precincts]);

  return {
    places
  }
}
