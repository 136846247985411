import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProvinces,
  getDistricts,
  getMuniCities,
  getBarangays,
  getPrecincts
} from 'actions/geopoliticalUnitActions';
import { geoPoliticalUnitSelector } from 'selectors/geopoliticalUnitSelector';

export function useGeopoliticalUnits({...filters}) {
  const { province, district, muniCity, barangay } = filters;
  const dispatch = useDispatch();
  const {
    provinces,
    districts,
    muniCities,
    barangays,
    precincts,
    provincesError,
    districtsError,
    muniCitiesError,
    barangaysError,
    precinctsError
  } = useSelector(geoPoliticalUnitSelector);

  useEffect(() => {
    dispatch(getProvinces());
    if (province) dispatch(getDistricts(province));
    if (district && province) dispatch(getMuniCities(province, district));
    if (muniCity && province && district) dispatch(getBarangays(province, district, muniCity));
    if (barangay && province && district && muniCity) dispatch(getPrecincts(province, district, muniCity, barangay));
  }, [dispatch, province, district, muniCity, barangay]);

  return {
    provinces,
    districts,
    muniCities,
    barangays,
    precincts,
    provincesError,
    districtsError,
    muniCitiesError,
    barangaysError,
    precinctsError
  };
};