import { 
    PROVINCES_LOAD_REQUEST, PROVINCES_LOAD_SUCCESS, PROVINCES_LOAD_FAILED,
    DISTRICTS_LOAD_REQUEST, DISTRICTS_LOAD_SUCCESS, DISTRICTS_LOAD_FAILED,
    MUNI_CITIES_LOAD_REQUEST, MUNI_CITIES_LOAD_SUCCESS, MUNI_CITIES_LOAD_FAILED,
    BARANGAYS_LOAD_REQUEST, BARANGAYS_LOAD_SUCCESS, BARANGAYS_LOAD_FAILED,
    PRECINCTS_LOAD_REQUEST, PRECINCTS_LOAD_SUCCESS, PRECINCTS_LOAD_FAILED
} from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    provinces: [],
    districts: [],
    muniCities: [],
    barangays: [],
    precincts: []
})

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case PROVINCES_LOAD_REQUEST:
            return {
                ...state,
                provinces: [],
                provincesError: null
            };
        case PROVINCES_LOAD_SUCCESS:
            return {
                ...state,
                provinces: action.payload.provinces,
                provincesError: null
            };
        case PROVINCES_LOAD_FAILED:
            return {
                ...state,
                provinces: [],
                provincesError: action.payload.errors
            };
        case DISTRICTS_LOAD_REQUEST:
            return {
                ...state,
                districts: [],
                districtsError: null
            };
        case DISTRICTS_LOAD_SUCCESS:
            return {
                ...state,
                districts: action.payload.districts,
                districtsError: null
            };
        case DISTRICTS_LOAD_FAILED:
            return {
                ...state,
                districts: [],
                districtsError: action.payload.errors
            };
        case MUNI_CITIES_LOAD_REQUEST:
            return {
                ...state,
                muniCities: [],
                muniCitiesError: null
            };
        case MUNI_CITIES_LOAD_SUCCESS:
            return {
                ...state,
                muniCities: action.payload.muniCities,
                muniCitiesError: null
            };
        case MUNI_CITIES_LOAD_FAILED:
            return {
                ...state,
                muniCities: [],
                muniCitiesError: action.payload.errors
            };
        case BARANGAYS_LOAD_REQUEST:
            return {
                ...state,
                barangays: [],
                barangaysError: null
            };
        case BARANGAYS_LOAD_SUCCESS:
            return {
                ...state,
                barangays: action.payload.barangays,
                barangaysError: null
            };
        case BARANGAYS_LOAD_FAILED:
            return {
                ...state,
                barangays: [],
                barangaysError: action.payload.errors
            };
        case PRECINCTS_LOAD_REQUEST:
            return {
                ...state,
                precincts: [],
                precinctsError: null
            };
        case PRECINCTS_LOAD_SUCCESS:
            return {
                ...state,
                precincts: action.payload.precincts,
                precinctsError: null
            };
        case PRECINCTS_LOAD_FAILED:
            return {
                ...state,
                precincts: [],
                precinctsError: action.payload.errors
            };
        default:
            return state;
    };
};