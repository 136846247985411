import './Header.css'
import { Button, Image, Stack, Container, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { logoutAction } from 'actions/sessionActions';
import logo from 'assets/logo.png';
import { sessionSelector } from 'selectors/sessionSelector';
import { toggleSidebarAction } from 'actions/sidebarActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const { user } = useSelector(sessionSelector);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const logOutUser = () => dispatch(logoutAction());

  const isAuthPage = ['/', '/login', '/request-for-demo'].includes(pathname);

  return (
    <header className="header">
      <Navbar expand="lg">
        <Container>
          <Stack className="w-100" direction="horizontal" gap={3}>
            <Navbar.Brand as={NavLink}>
              <FontAwesomeIcon color="white" icon={faBars} className="me-2" onClick={() => dispatch(toggleSidebarAction())}/>
              <Image src={logo} height={30} alt="Logo"/>
            </Navbar.Brand>
            <div className="d-flex justify-content-end w-100">
              {user && !isAuthPage ? (
                <div>
                  <span className="me-2 text-white">{user.name}</span>
                  <Button variant="link" onClick={logOutUser}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </Button>
                </div>
              ) : (
                <NavLink to="/login" className="btn btn-primary">Login</NavLink>
              )}                
            </div>            
          </Stack>        
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;