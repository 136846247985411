import { Button, Container, Form, Stack, Spinner } from "react-bootstrap";
import { useState } from "react";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPasswordAction } from "actions/sessionActions";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [alertConfig, setAlertConfig] = useState({
        heading: "",
        message: "",
        variant: "danger",
        show: false
    });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleAlertClose = () => {
        setAlertConfig((prev) => ({ ...prev, show: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await dispatch(forgotPasswordAction(email));
        setIsLoading(false);
        setAlertConfig((prev) => ({ ...prev, show: true, heading: "Got it!", message: "If the email exists, we'll send you a password reset email shortly.", variant: "success" }));
    };

    return(
        <section id="forgot-password">
            <AlertMessage
                heading={alertConfig.heading}
                message={alertConfig.message}
                variant={alertConfig.variant}
                visibility={alertConfig.show}
                onClose={handleAlertClose}
            />
            <Container className="mt-5 w-50">
                <Form className="p-5 bg-light rounded" onSubmit={handleSubmit}>                    
                    <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                name="email" 
                                type="email" 
                                placeholder="Enter your email" 
                                onChange={handleChange} 
                                value={email}
                            />
                        </Form.Group>
                        <Stack gap={2}>
                            <Button type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}Sending...
                                    </>
                                ) : (
                                    'Reset Password'
                                )}
                            </Button>
                            <Button variant="secondary" onClick={() => navigate('/login')}>
                                Back to Login
                            </Button>
                        </Stack>
                    </Stack>
                </Form>
            </Container>
        </section>
    )
}

export default ForgotPassword;