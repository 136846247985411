import getURL from "api/config";
import { GET } from "api/index";
import * as types from "constants/actionTypes";
import { getError } from "helpers";

export function getBarangayListAction() {
    return function action(dispatch) {
        const url = getURL("GET_BARANGAY_LIST");
        const request = GET(url);

        dispatch({
            type: types.BARANGAY_LIST_LOAD_REQUEST,
            payload: { barangays: [] }
        });

        request.then((response) => {
            const barangays = response?.data?.result || [];

            dispatch({
                type: types.BARANGAY_LIST_LOAD_SUCCESS,
                payload: { barangays }
            })
        }, (error) => {
            dispatch({
                type: types.BARANGAY_LIST_LOAD_FAILED,
                payload: {
                    errors: getError(error)
                }
            });
        });   
    };
};
