import { useParams } from 'react-router-dom';
import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { kpiSelector } from 'selectors/kpiSelector';
import { getKpiAction, updateKpiAction } from 'actions/kpiActions';
import KpiForm from './KpiForm';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import LoadingSpinner from 'components/common/LoadingSpinner';
const EditKpi = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { kpi, isLoading } = useSelector(kpiSelector);
  const [alertConfig, setAlertConfig] = useState({
    heading: '',
    message: '',
    variant: 'danger',
    show: false,
    dismissible: true
  });

  const showAlert = useCallback((heading, message, variant = 'danger') => {
    setAlertConfig({
      heading,
      message,
      variant,
      dismissible: true,
      show: true
    });
  }, []);

  const handleAlertClose = useCallback(() => {
    setAlertConfig((prev) => ({ ...prev, show: false }));
  }, []);

  useEffect(() => {
    const fetchKpi = async () => dispatch(getKpiAction(id));

    fetchKpi();
  }, [dispatch, showAlert, id]);

  const handleSubmit = async (data) => {
    try {
      dispatch(updateKpiAction(id, data));
    } catch (error) {
      showAlert('Something went wrong', error.message || 'Failed to update KPI');
    }
  }


  return kpi ?(
    <section id='editKpi'>
      <AlertMessage
        heading={alertConfig.heading}
        message={alertConfig.message}
        variant={alertConfig.variant}
        visibility={alertConfig.show}
        onClose={handleAlertClose}
        dismissible={alertConfig.dismissible}
      />

      <KpiForm
        kpi={kpi}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        mode='edit'
      />
    </section>
  ) : (
    <LoadingSpinner />
  )
}

export default EditKpi;