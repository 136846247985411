import { Spinner } from 'react-bootstrap';

const LoadingSpinner = () => {
  return (
    <div className='d-flex justify-content-center align-items-center h-100'>
      <Spinner animation='border' role='status' />
    </div>
  );
};

export default LoadingSpinner;
