import { SEND_REQUEST_FOR_DEMO_EMAIL_FAILED, SEND_REQUEST_FOR_DEMO_EMAIL_SUCCESS } from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    requestForDemoEmailStatus: null
});

export default function reduce (state = initialState, action) {
    switch (action.type) {
        case SEND_REQUEST_FOR_DEMO_EMAIL_SUCCESS:
            return {
                ...state,
                requestForDemoEmailStatus: action.payload.status
            };
        case SEND_REQUEST_FOR_DEMO_EMAIL_FAILED:
            return {
                ...state,
                requestForDemoEmailStatus: action.payload.status
            };
        default:
            return state;
    }
};