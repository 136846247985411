import getURL from "api/config";
import { GET } from "api/index";
import * as types from "constants/actionTypes";
import { getError } from "helpers";

export function getProvinceListAction() {
    return function action(dispatch) {
        const url = getURL("GET_PROVINCE_LIST");
        const request = GET(url);

        dispatch({
            type: types.PROVINCE_LIST_LOAD_REQUEST,
            payload: { provinces: [] }
        });

        request.then((response) => {
            const provinces = response?.data?.result || [];
            dispatch({
                type: types.PROVINCE_LIST_LOAD_SUCCESS,
                payload: { provinces }
            })
        }, (error) => {
            dispatch({
                type: types.PROVINCE_LIST_LOAD_FAILED,
                payload: {
                    errors: getError(error)
                }
            });
        });   
    };
};
