import getURL from "api/config";
import { GET, PUT, POST } from "api/index";
import {
    GET_VOTER_REQUEST,
    GET_VOTER_DISTRIBUTION_REQUEST,
    VOTER_LIST_LOAD_REQUEST,
    VOTER_LIST_LOAD_SUCCESS,
    VOTER_LIST_LOAD_FAILED,
    GET_VOTERS_REQUEST,
    ADD_VOTER_SUCCESS,
    ADD_VOTER_FAILED,
    UPDATE_VOTER_REQUEST,
    UPDATE_VOTER_SUCCESS,
    UPDATE_VOTER_FAILED,
    CLEAR_VOTER_ERRORS,
    UPLOAD_VOTERS_REQUEST,
    UPLOAD_VOTERS_SUCCESS,
    UPLOAD_VOTERS_FAILED
} from "constants/actionTypes";
import { getError } from "helpers";
import { MULTIPART_HEADERS } from "constants/http";

export function getVoterListAction(data) {
    return function action (dispatch) {
        const url = getURL("GET_VOTER_LIST");
        const request = POST(url, data);

        dispatch({
            isLoading: true,
            type: VOTER_LIST_LOAD_REQUEST,
            payload: { voters: [], total: 0 }
        })
    
        request.then((response) => {
            const voters = response?.data?.result?.data?.data || {};
            const total = response?.data?.result?.data?.total || 0;
            const lastPage = response?.data?.result?.data?.last_page || 0;
            const currentPage = response?.data?.result?.data?.current_page || 0;
            dispatch({
                isLoading: false,
                type: VOTER_LIST_LOAD_SUCCESS,
                payload: { voters, total, lastPage, currentPage }
            })
        }, (error) => {
            dispatch({
                type: VOTER_LIST_LOAD_FAILED,
                payload: {
                    isLoading: false,
                    errors: getError(error)
                }
            })
        });
    };        
};

export function addVoterAction(data) {
    return function action(dispatch) {
        const url = getURL("INSERT_VOTER");
        const request = POST(url, data);

        request.then((response) => {
            dispatch({
                type: ADD_VOTER_SUCCESS,
                payload: { voter: response.data.result }
            })
        }, () => {
            dispatch({
                type: ADD_VOTER_FAILED,
                payload: { errors: 'Failed to add voter' }
            })
        });
    };
}

export function updateVoterAction(id, data) {
    console.log(id);
    return function action(dispatch) {
        const url = getURL("UPDATE_VOTER")(id);
        const request = PUT(url, data);

        dispatch({
            type: UPDATE_VOTER_REQUEST
        })

        request.then((response) => {
            dispatch({
                type: UPDATE_VOTER_SUCCESS,
                payload: { voter: response?.data?.result }
            })
        }, () => {
            dispatch({
                type: UPDATE_VOTER_FAILED,
                payload: { errors: 'Failed to update voter' }
            }) 
        });
    };
}

export function clearVoterErrorsAction() {
    return function action(dispatch) {
        dispatch({
            type: CLEAR_VOTER_ERRORS
        })
    };
};

export function uploadVotersAction(data) {
    return function action(dispatch) {
        const url = getURL("UPLOAD_VOTERS");
        const request = POST(url, data, MULTIPART_HEADERS);

        dispatch({
            type: UPLOAD_VOTERS_REQUEST,
            payload: { isLoading: true, uploadError: false }
        })

        request.then((response) => {
            dispatch({
                type: UPLOAD_VOTERS_SUCCESS,
                payload: { voters: response.data.result, isLoading: false, uploadError: false }
            })
        }, (error) => {
            dispatch({
                type: UPLOAD_VOTERS_FAILED,
                payload: { errors: getError(error), isLoading: false, uploadError: true }
            })
        });
    };
};

export function getVoters(queryParams) {
    return function action(dispatch) {
        const url = getURL("GET_VOTERS");
        const request = GET(url, {}, {}, queryParams);

        dispatch({
            type: GET_VOTERS_REQUEST,
            payload: { 
                isLoading: true,
                votersError: null 
            }
        });

        request.then((response) => {
            const total = response?.data?.result?.data?.total;
            const voters = response?.data?.result?.data?.data;
            const votersColumns = response?.data?.result?.columns;
            const votersCurrentPage = response?.data?.result?.data?.current_page;
            const votersLastPage = response?.data?.result?.data?.last_page;
            dispatch({
                type: GET_VOTERS_REQUEST,
                payload: { 
                    isLoading: false,
                    total,
                    voters, 
                    votersColumns,
                    votersCurrentPage,
                    votersLastPage,
                    votersError: null
                }
            });
        }, (error) => {
            dispatch({
                type: GET_VOTERS_REQUEST,
                payload: { 
                    isLoading: false,
                    voters: [],
                    votersError: getError(error)
                }
            });
        });
    };
};

export function getVoter(voterId) {
    return function action(dispatch) {
        const url = getURL("GET_VOTER")(voterId);
        const request = GET(url);

        dispatch({
            type: GET_VOTER_REQUEST,
            payload: { isLoading: true, voter: null, voterError: null }
        });

        request.then((response) => {
            const voter = response?.data?.result;
            dispatch({
                type: GET_VOTER_REQUEST,
                payload: { voter, isLoading: false, voterError: null }
            })
        }, (error) => {
            dispatch({
                type: GET_VOTER_REQUEST,
                payload: { isLoading: false, voter: null, voterError: getError(error) }
            })
        });
    };
};

export function getVoterDistribution(queryParams, data) {
    return function action(dispatch) {
        const url = getURL("GET_VOTER_DISTRIBUTION");
        const request = GET(url, {}, {}, queryParams, {filters: data});

        dispatch({
            type: GET_VOTER_DISTRIBUTION_REQUEST,
            payload: { overallDistribution: {}, historicalTrend: {} }
        });

        request.then((response) => {
            const overallDistribution = response?.data?.result?.overall_distribution;
            const historicalTrend = response?.data?.result?.historical_trend;
            dispatch({
                type: GET_VOTER_DISTRIBUTION_REQUEST,
                payload: { overallDistribution, historicalTrend }
            })
        }, (error) => {
            dispatch({
                type: GET_VOTER_DISTRIBUTION_REQUEST,
                payload: { voterDistributionError: getError(error) }
            })
        });
    };
};