import "./FilterUsers.css";
import { Button, Modal, Container, Form, Stack, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useGeopoliticalUnits } from "hooks/useGeopoliticalUnits";
import { SEXES } from "constants/common";
import { useVariables } from "hooks/useVariables";

function FilterUsers({ show, onHide }) {
    const [filters, setFilters] = useState({});
    const { provinces, districts, muniCities, precincts, barangays } = useGeopoliticalUnits({
        province: filters.province,
        district: filters.district,
        muniCity: filters.municity,
        barangay: filters.barangay,
        precinct: filters.precinct
    });
    const { variables } = useVariables();
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleClose = () => {
        setFilters({});
        onHide();
    };

    return (
        <Modal id="filterUsers" show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Filter Users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Stack gap={2}>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={filters.name || ''}
                                    placeholder="Name"
                                    onChange={handleFilterChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Sex</Form.Label>
                                <Form.Select
                                    name="sex"
                                    value={filters.sex || ''}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">All</option>
                                    {
                                        SEXES.map((option) => (
                                            <option value={option === 'Male' ? 'M' : 'F'}>{option}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Birthdate</Form.Label>
                                <Form.Control
                                    name="birthdate"
                                    type="date"
                                    value={filters.birthdate || ''}
                                    onChange={handleFilterChange}
                                />
                            </Form.Group>
                        </Row>
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                name="address"
                                value={filters.address || ''}
                                placeholder="Address"
                                onChange={handleFilterChange}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Province</Form.Label>
                            <Form.Select
                                name="province"
                                value={filters.province || ''}
                                onChange={handleFilterChange}
                            >
                                <option value="">All</option>
                                {
                                    provinces.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>District</Form.Label>
                                <Form.Select
                                    name="district"
                                    value={filters.district || ''}
                                    onChange={handleFilterChange}
                                    disabled={!filters.province}
                                >
                                    <option value="">{filters.province ? 'All' : 'Select a province first'}</option>
                                    {
                                        districts.map((option) => (
                                            <option value={option}>{option}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label> Municipality/City</Form.Label>
                                <Form.Select
                                    name="municity"
                                    value={filters.municity || ''}
                                    onChange={handleFilterChange}
                                    disabled={!filters.district}
                                >
                                    <option value="">{filters.district ? 'All' : 'Select a district first'}</option>
                                    {
                                        muniCities.map((option) => (
                                            <option value={option}>{option}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Barangay</Form.Label>
                                <Form.Select
                                    name="barangay"
                                    value={filters.barangay || ''}
                                    onChange={handleFilterChange}
                                    disabled={!filters.municity}
                                >
                                    <option value="">{filters.municity ? 'All' : 'Select a municipality/city first'}</option>
                                    {
                                        barangays.map((option) => (
                                            <option value={option}>{option}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Precinct</Form.Label>
                                <Form.Select
                                    name="precinct"
                                    value={filters.precinct || ''}
                                    onChange={handleFilterChange}
                                    disabled={!filters.barangay}
                                >
                                    <option value="">{filters.barangay ? 'All' : 'Select a barangay first'}</option>
                                    {
                                        precincts.map((option) => (
                                            <option value={option}>{option}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row>
                            {
                                variables.map((variable) => (
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>
                                                {variable.name}
                                            </Form.Label>
                                            <Form.Select name={variable.name} onChange={handleFilterChange}>
                                                <option value="">All</option>
                                                {
                                                    variable.variable_options.map((option) => (
                                                        <option value={option.variable_options_id}>{option.name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Stack>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => onHide(filters)}>Filter</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FilterUsers;