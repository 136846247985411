import "./Analyze.css";
import { Card, Col, Form, Row, Stack } from "react-bootstrap";
import { useGeopoliticalUnits } from "hooks/useGeopoliticalUnits";
import { v4 as uuidv4 } from "uuid";
import { getVariableAction, getVariableListAction } from "actions/variableActions";
import { useDispatch, useSelector } from "react-redux";
import { variableSelector } from "selectors/variableSelector";
import { useCallback, useEffect, useState } from "react";
import { getVoterDistribution } from "actions/voterActions";
import { voterSelector } from "selectors/voterSelector";
import { Line, LineChart, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { generateRandomColor } from "helpers";
import { INTERVALS } from "constants/common";

function Analyze() {
    const dispatch = useDispatch();
    const [options, setOptions] = useState({});
    const {
        provinces,
        districts,
        muniCities,
        barangays,
        precincts
    } = useGeopoliticalUnits(options);
    const { variables, variable } = useSelector(variableSelector);
    const { overallDistribution, historicalTrend } = useSelector(voterSelector);

    useEffect(() => {
        dispatch(getVariableListAction())
    }, [dispatch])

    useEffect(() => {
        if (options.variable_id) {
            dispatch(getVariableAction(options.variable_id))
        }
    }, [options.variable_id, dispatch])


    const handleChange = (e) => {
        setOptions((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = useCallback(() => {
        dispatch(getVoterDistribution(options, {}));
    }, [options, dispatch]);


    useEffect(() => {
        if (options.variable_id && options.start_date && options.end_date) {
            handleSubmit()
        }
    }, [options, handleSubmit])

    return (
        <section id="analyze">
            <Card>
                <Card.Header>
                    Generate Graphs
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Variable</Form.Label>
                                    <Form.Select name="variable_id" onChange={handleChange} value={options.variable_id}>
                                        <option value="">Select one</option>
                                        {
                                            variables?.map((option) => (
                                                <option key={option.variable_id} value={option.variable_id}>{option.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Interval</Form.Label>
                                    <Form.Select name="interval" onChange={handleChange} value={options.interval}>
                                        <option value="">Select one</option>
                                        {
                                            INTERVALS.map((option) => (
                                                <option key={uuidv4()} value={option}>{option}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Province</Form.Label>
                                    <Form.Select name="province" onChange={handleChange} value={options.province}>
                                        <option value="">Select Province</option>
                                        {
                                            provinces.map((province) => (
                                                <option key={uuidv4()} value={province}>{province}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select District</Form.Label>
                                    <Form.Select name="district" disabled={!options.province} onChange={handleChange} value={options.district}>
                                        <option value="">Select District</option>
                                        {
                                            districts.map((district) => (
                                                <option key={uuidv4()} value={district}>{district}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Municipality/City</Form.Label>
                                    <Form.Select name="muniCity" onChange={handleChange} value={options.muniCity}>
                                        <option value="">Select Municipality/City</option>
                                        {
                                            muniCities.map((muniCity) => (
                                                <option key={uuidv4()} value={muniCity}>{muniCity}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Barangay</Form.Label>
                                    <Form.Select name="barangay" onChange={handleChange} value={options.barangay}>
                                        <option value="">Select Barangay</option>
                                        {
                                            barangays.map((barangay) => (
                                                <option key={uuidv4()} value={barangay}>{barangay}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Precinct</Form.Label>
                                    <Form.Select name="precinct" onChange={handleChange} value={options.precinct}>
                                        <option value="">Select Precinct</option>
                                        {
                                            precincts.map((precinct) => (
                                                <option key={uuidv4()} value={precinct}>{precinct}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control type="date" name="start_date" onChange={handleChange} defaultValue={options.start_date} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control type="date" name="end_date" onChange={handleChange} defaultValue={options.end_date} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr />
                        <Stack direction="horizontal" gap={2}>
                            {overallDistribution &&
                                <div className="charts">
                                    <ResponsiveContainer>
                                        <BarChart
                                            layout="vertical"
                                            data={Object.keys(overallDistribution).map((k, _v) => [k, overallDistribution[k]])}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis type="number" />
                                            <YAxis type="category" dataKey={(data) => data[0]} />
                                            <Tooltip />
                                            <Bar dataKey={(data) => data[1].replace('%', '')} height={100}>
                                                {
                                                    Object.keys(overallDistribution).map(() => (
                                                        <Cell key={uuidv4()} fill={generateRandomColor()} />
                                                    ))
                                                }
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            }
                            {
                                historicalTrend?.data?.length > 0 &&
                                <div className="charts">
                                    <ResponsiveContainer>
                                        <LineChart
                                            height={300}
                                            width={730}
                                            data={
                                                Object.keys(historicalTrend?.data[0]).map((key) => {
                                                    const transformedObject = { interval: key }; // Start with the interval key
                                                    Object.entries(historicalTrend?.data[0][key]).forEach(([innerKey, value]) => {
                                                        transformedObject[innerKey] = parseFloat(value.replace('%', '')); // Convert values
                                                    });
                                                    return transformedObject;
                                                })
                                            }
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="interval" />
                                            <YAxis />
                                            <Tooltip />
                                            {
                                                variable?.variable_options?.map((option) => {
                                                    return (
                                                        <Line type="monotone" dataKey={option.name} stroke={generateRandomColor()} />
                                                    )
                                                })
                                            }
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            }
                        </Stack>
                    </Form>
                </Card.Body>
            </Card>
        </section>
    )
}

export default Analyze;