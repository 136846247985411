import "./RequestForDemo.css";
import { sendRequestForDemoEmailAction } from "actions/publicActions";
import { publicSelector } from "selectors/publicSelector";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AlertMessage from "components/AlertMessage/AlertMessage";

function RequestForDemo() {
    const dispatch = useDispatch();
    const { requestForDemoEmailStatus } = useSelector(publicSelector);

    const [formData, setFormData] = useState({
        email: "",
        facebook: "",
        firstName: "",
        lastName: "",
        mobileNumber: ""
    });
    const [showForm, setShowForm] = useState(true);
    const [alertConfig, setAlertConfig] = useState({
        show: false,
        heading: '',
        message: '',
        variant: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRequestForDemo = () => {
        dispatch(sendRequestForDemoEmailAction(formData));
    };    

    useEffect(() => {
        const getAlertConfig = (status) => {
            const alertConfigs = {
                200: {
                    show: true,
                    heading: 'Thank you!',
                    message: 'We will contact you soon.',
                    variant: 'success'
                },
                400: {
                    show: true,
                    heading: 'Error',
                    message: 'Please review the form and try again.',
                    variant: 'danger'
                },
                500: {
                    show: true,
                    heading: 'Error',
                    message: 'Internal server error. Please try again later or contact support.',
                    variant: 'danger'
                }
            };

            return alertConfigs[status] || 
                   (status >= 400 && status < 500 ? alertConfigs[400] : 
                   (status >= 500 ? alertConfigs[500] : alertConfig));
        }

        if (requestForDemoEmailStatus !== null) {   
            setShowForm(requestForDemoEmailStatus !== 200);
            setAlertConfig(getAlertConfig(requestForDemoEmailStatus));
        }
    }, [requestForDemoEmailStatus, alertConfig]);

    return(
        <section id="request-for-demo">

            <AlertMessage heading={alertConfig.heading} message={alertConfig.message} visibility={alertConfig.show} variant={alertConfig.variant} onClose={() => setAlertConfig({ ...alertConfig, show: false })} />

            <Container className="text-center text-uppercase line-height-0">
                <h1><span className="text-red">Pull</span> the Rug</h1>
                <h1>Out from Your</h1>
                <h1 className="text-red">Your <span className="text-yellow">competitors</span>!</h1>
                <p className="my-3 text-normalcase">
                    Our face-to-face demo is available for a fee.<br />
                    If you are interested, please fill out the fields below.
                </p>

                {showForm && <Form className="mt-4">
                    <Stack gap={2}>
                        <Row>
                            <Col lg={4}><Form.Label>First Name</Form.Label></Col>
                            <Col lg={8}><Form.Control name="firstName" placeholder="First Name" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={4}><Form.Label>Last Name</Form.Label></Col>
                            <Col lg={8}><Form.Control name="lastName" placeholder="Last Name" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={4}><Form.Label>Email</Form.Label></Col>
                            <Col lg={8}><Form.Control name="email" placeholder="Email" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={4}><Form.Label>Mobile Number</Form.Label></Col>
                            <Col lg={8}><Form.Control name="mobileNumber" placeholder="Mobile Number" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={4}><Form.Label>Facebook</Form.Label></Col>
                            <Col lg={8}><Form.Control name="facebook" placeholder="Facebook" onChange={handleChange} /></Col>
                        </Row>
                    </Stack>                    
                    <Button className="my-3 btn-lg" onClick={handleRequestForDemo} >Send</Button>
                <AlertMessage 
                    heading="Error" 
                    message="Unable to send your request due to a CORS issue. Please try again later or contact support." 
                    visibility={requestForDemoEmailStatus === 'CORS_ERROR'} 
                    variant="danger" 
                    onClose={() => dispatch({ type: 'RESET_REQUEST_FOR_DEMO_EMAIL_STATUS' })}
                />
                </Form>}

                <small>Fees, schedule options and other details will be sent to your email.</small>
            </Container>            
            <div className="mt-5 text-center">Copyright 2024 Edgepoint Solutions, Inc. All rights reserved.</div>
        </section>
    )
}

export default RequestForDemo;