import "./ManageVariables.css";
import { Button, Card, Col, Row, Stack, Table, Badge, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getVariableListAction } from "actions/variableActions";
import { variableSelector } from "selectors/variableSelector";
import { useEffect, useState } from "react";
import VariableModal from "components/Modals/VariableModal/VariableModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faRotateLeft, faPlus } from "@fortawesome/free-solid-svg-icons";

function ManageVariables() {
    const dispatch = useDispatch();
    const { variables } = useSelector(variableSelector);
    const [variableList, setVariableList] = useState([]);
    const [ showVariableModal, setShowVariableModal ] = useState({ show: false, variable: {} });
    const newVariable = {
        name: "",
        type: "Preference", 
        is_kpi: false,
        is_active: true,
        variable_options: []
    };

    const handleAddVariable = () => {
        setShowVariableModal({ show: true, variable: newVariable });
    };

    const handleReset = () => {
        setVariableList([]);
        dispatch(getVariableListAction());
    }

    const handleVariableModalClose = () => {
        setShowVariableModal({ show: false, variable: {} });
    };

    useEffect(() => {
        dispatch(getVariableListAction());
    }, [dispatch]);

    useEffect(() => {
        setVariableList(variables);
    }, [variables]);

    return(
        <section id="manageVariables">
            { showVariableModal.show && <VariableModal show={showVariableModal.show} onHide={handleVariableModalClose} variable={showVariableModal.variable} /> }
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Stack direction="horizontal" gap={2} className="d-flex justify-content-between">
                                    <div>
                                        <span>Manage Variables</span>{" "}
                                        <Badge bg="success" size="sm">{variableList.length}</Badge>
                                    </div>
                                    <div>
                                        <Button variant="success" size="sm" onClick={handleAddVariable}><FontAwesomeIcon icon={faPlus} /></Button>{" "}
                                        <Button variant="outline-primary" size="sm" onClick={handleReset}><FontAwesomeIcon icon={faRotateLeft} /></Button>
                                    </div>
                                </Stack>
                            </Card.Header>
                            <Card.Body className="wrapper">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th width="2%">No.</th>
                                            <th width="46%">Name</th>
                                            <th width="15%">Data Type</th>
                                            <th width="15%">KPI Type</th>
                                            <th width="12%">No. of Options</th>
                                            <th width="12%">Enabled</th>
                                            <th width="8%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            variableList.map((variable, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{variable.name}</td>
                                                        <td>{variable.type}</td>
                                                        <td>{variable.kpi_type || 'Not KPI'}</td>
                                                        <td>{variable.type === 'string' ? 'N/A' : variable.variable_options.length}</td>
                                                        <td>{variable.is_active ? 'Yes' : 'No'}</td>
                                                        <td><Button variant="primary" size="sm" onClick={() => setShowVariableModal({ show: true, variable: variable })}><FontAwesomeIcon icon={faPencil} /></Button></td>
                                                    </tr>                                                
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>                
            </Container>
        </section>
    )
}

export default ManageVariables;