import { Form, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const CriteriaSection = ({ criteria, setCriteria, onAdd, onRemove, onChange }) => {
	return (
		<Form.Group as={Row}>
			<Form.Label column sm={3}>Criteria (%)</Form.Label>
			<Col sm={9}>
				<Row>
					<Form.Label column sm={6}><small className='text-muted'>Label</small></Form.Label>
					<Form.Label column sm={3}><small className='text-muted'>Lower Limit</small></Form.Label>
					<Form.Label column sm={3}><small className='text-muted'>Upper Limit</small></Form.Label>
				</Row>
				{criteria.map((item, index) => (
					<Row className='mb-3' key={index}>
						<Form.Group as={Col}>
							<Form.Control
								name='label'
								value={item.label}
								onChange={(e) => onChange(index, 'label', e.target.value)}
							/>
						</Form.Group>
						<Col sm={3}>
							<Form.Control
								name='lower_limit'
								value={item.lower_limit === '' ? '' : parseInt(item.lower_limit)}
								type='number'
								min={0}
								onChange={(e) => onChange(index, 'lower_limit', e.target.value)}
							/>
						</Col>
						<Col sm={3}>
							<Form.Control
								name='upper_limit'
								value={item.upper_limit === '' ? '' : parseInt(item.upper_limit)}
								type='number'
								min={0}
								onChange={(e) => onChange(index, 'upper_limit', e.target.value)}
							/>
						</Col>
						{criteria.length > 2 && (
							<Col sm={1}>
								<Button variant='danger' size='sm' onClick={() => onRemove(index)}>
									<FontAwesomeIcon icon={faTrash} />
								</Button>
							</Col>
						)}
					</Row>
				))}
				<Button
					variant='secondary'
					onClick={onAdd}
					disabled={criteria.length >= 5}
				>
					Add Criteria
				</Button>
			</Col>
		</Form.Group>
	)
}

export default CriteriaSection;