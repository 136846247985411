import { 
    BARANGAY_LIST_LOAD_FAILED,
    BARANGAY_LIST_LOAD_REQUEST,
    BARANGAY_LIST_LOAD_SUCCESS
} from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    barangays: [],
})

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case BARANGAY_LIST_LOAD_REQUEST:
            return {
                ...state,
                barangays: []
            };
        case BARANGAY_LIST_LOAD_SUCCESS:
            return {
                ...state,
                barangays: action.payload.barangays
            };
        case BARANGAY_LIST_LOAD_FAILED:
            return {
                ...state,
                errors: action.payload.errors
            };
        default:
            return state;
    }
}