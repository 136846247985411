import { useNavigate } from "react-router-dom";
import "./Welcome.css";
import { Button, Container } from "react-bootstrap";

function Welcome() {
    const navigate = useNavigate();

    return(
        <section id="welcome">
            <Container className="text-center text-uppercase line-height-0">
                <h1>Manage</h1>
                <h1>Your</h1>
                <h1 className="text-red">Campaign</h1>
                <h5>From your <span className="text-yellow">fingertips</span>.</h5>

                <Button className="mt-5" onClick={() => navigate("/request-for-demo")}>Request for a Demo</Button>
            </Container>
            <div className="pin-bottom">Copyright 2024 Edgepoint Solutions, Inc. All rights reserved.</div>
        </section>
    )
}

export default Welcome;