import "./Gather.css";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, Container, FormControl, InputGroup, Table } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { getVoters } from "actions/voterActions";
import { voterSelector } from "selectors/voterSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import GatherForm from "./GatherForm/GatherForm";
import { convertPgArray } from "helpers";
import { getUser } from "actions/userActions";
import { userSelector } from "selectors/userSelector";
import { sessionSelector } from "selectors/sessionSelector";
import { useGeopoliticalUnits } from "hooks/useGeopoliticalUnits";

function Gather() {
    const dispatch = useDispatch();
    const { voters, votersCurrentPage, votersLastPage } = useSelector(voterSelector);
    const [voterId, setVoterId] = useState(null);
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [voterList, setVoterList] = useState([]);
    const { user } = useSelector(userSelector);
    const { userId } = useSelector(sessionSelector);
    const { provinces, districts, muniCities, barangays, precincts } = useGeopoliticalUnits(filters);
    const [voterName, setVoterName] = useState('');

    const getUserGeopoliticalUnits = (arr1, arr2) => {
        // convert arr2 to array
        const comparatorArr = convertPgArray(arr2);
        // if reference array is null, then return blank array.
        if (!arr1) {
            return [];
        };

        // if comparator array is empty, then return the reference array. 
        if (comparatorArr.length === 0) {
            return arr1;
        };

        return arr1.filter(element => comparatorArr.includes(element));
    };

    const fetchData = useCallback(() => {
        dispatch(getVoters({ ...filters, voterId, page }));
    }, [dispatch, filters, voterId, page]);

    const handleSearch = () => {
        if (voterName) {
            dispatch(getVoters({ ...filters, voterId, name: voterName, page }));
        };
    };

    const handleClearFilter = (filter) => {
        setVoterName('');
        setVoterList([]);
        setFilters((prev) => {
            switch (filter) {
                case "province":
                    return {};
                case "district":
                    return { province: prev['province'] };
                case "muniCity":
                    return { province: prev['province'], district: prev['district'] };
                case "barangay":
                    return { province: prev['province'], district: prev['district'], muniCity: prev['muniCity'] };
                case "precinct":
                    return { province: prev['province'], district: prev['district'], muniCity: prev['muniCity'], barangay: prev['barangay'] };
                default:
                    return {};
            }
        });
    };

    useEffect(() => {
        if (filters.province && filters.district && filters.muniCity && filters.barangay) {
            fetchData();
        }
    }, [filters, fetchData, page]);

    useEffect(() => {
        if (user) {
            const userProvinces = convertPgArray(user.provinces);
            if (userProvinces.length === 1) {
                setFilters((prev) => ({
                    ...prev,
                    province: userProvinces[0]
                }));
            };

            const userDistricts = convertPgArray(user.districts);
            if (userDistricts.length === 1) {
                setFilters((prev) => ({
                    ...prev,
                    district: userDistricts[0]
                }));
            };

            const userMuniCities = convertPgArray(user.municities);
            if (userMuniCities.length === 1) {
                setFilters((prev) => ({
                    ...prev,
                    muniCity: userMuniCities[0]
                }));
            };

            const userBarangays = convertPgArray(user.barangays);
            if (userBarangays.length === 1) {
                setFilters((prev) => ({
                    ...prev,
                    barangay: userBarangays[0]
                }));
            };

            const userPrecincts = convertPgArray(user.precincts);
            if (userPrecincts.length === 1) {
                setFilters((prev) => ({
                    ...prev,
                    precinct: userPrecincts[0]
                }));
            };
        };
    }, [user]);

    useEffect(() => {
        setFilters({});
        dispatch(getUser(userId));
    }, [dispatch, userId]);

    useEffect(() => {
        if (voters) {
            setVoterList(voters);
        }
    }, [voters]);

    const renderTable = (items, unit) => {
        return <Table striped bordered hover>
            <tbody>
                {
                    items.map((item) => (
                        <tr key={uuidv4()} onClick={() => setFilters((prev) => ({ ...prev, [unit]: item }))}>
                            <td>{item}</td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    };

    return (
        <section id="gather">
            <Container>
                <Card>
                    <Card.Header>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>My Voters</span>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div>
                            <InputGroup className="mb-2">
                                <FormControl placeholder="Search for voter" onChange={(e) => setVoterName(e.target.value)} value={voterName} />
                                <Button variant="outline-secondary" onClick={handleSearch}>Search</Button>
                            </InputGroup>
                        </div>
                        <div className="d-flex flex-wrap gap-2 mb-3">
                            {
                                Object.keys(filters).map((filter) => (
                                    <Badge key={uuidv4()} className="mx-1">
                                        {filters[filter]}
                                        {
                                            ((filter === 'province' && convertPgArray(user.provinces).length === 0) ||
                                                (filter === 'district' && convertPgArray(user.districts).length === 0) ||
                                                (filter === 'muniCity' && convertPgArray(user.municities).length === 0) ||
                                                (filter === 'barangay' && convertPgArray(user.barangays).length === 0) ||
                                                (filter === 'precinct' && convertPgArray(user.precints).length === 0)) && <FontAwesomeIcon icon={faXmark} className="ms-2" onClick={() => handleClearFilter(filter)} />
                                        }
                                    </Badge>
                                ))
                            }
                        </div>
                        {!filters.province && renderTable(getUserGeopoliticalUnits(provinces, user?.provinces), "province")}
                        {!filters.district && filters.province && renderTable(getUserGeopoliticalUnits(districts, user?.districts), "district")}
                        {!filters.muniCity && filters.district && filters.province && renderTable(getUserGeopoliticalUnits(muniCities, user?.municities), "muniCity")}
                        {!filters.barangay && filters.muniCity && filters.district && filters.province && renderTable(getUserGeopoliticalUnits(barangays, user?.barangays), "barangay")}
                        {!filters.precinct && filters.barangay && filters.muniCity && filters.district && filters.province && renderTable(getUserGeopoliticalUnits(precincts, user?.precincts), "precinct")}
                        {
                            !voterId && voterList.length > 0 && <div>
                                <h5>Available voters ({voterList.length}), page {page}</h5>
                                <hr />
                                <Table striped bordered hover>
                                    <tbody>
                                        {
                                            voterList.map((voter) => (
                                                <tr key={uuidv4()} onClick={() => setVoterId(voter.voter_id)}>
                                                    <td>
                                                        {voter.name}
                                                        <Badge bg="success" className="mx-1">
                                                            {voter.voter_responses.length}
                                                        </Badge>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            voterList.length > 0 && (votersCurrentPage > 1) && <Button onClick={() => setPage((prev) => prev - 1)} className="mx-2">Prev Page</Button>
                        }
                        {
                            voterList.length > 0 && (votersCurrentPage < votersLastPage) && <Button onClick={() => setPage((prev) => prev + 1)}>Next Page</Button>
                        }
                        {
                            voterId && <GatherForm voterId={voterId} onClose={() => setVoterId(null)} onFinish={() => setVoterId(null)} />
                        }
                    </Card.Body>
                </Card>
            </Container>
        </section>
    )
}

export default Gather;