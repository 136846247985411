import { 
    ROLE_LIST_LOAD_FAILED, 
    ROLE_LIST_LOAD_SUCCESS,
    ADD_ROLE_SUCCESS,
    ADD_ROLE_FAILED
} from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    roles: [],
    role: null,
    total: 0
});

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case ROLE_LIST_LOAD_SUCCESS:
            return {
                ...state,
                roles: action.payload.roles,
                total: action.payload.total,
                error: null
            };
        case ROLE_LIST_LOAD_FAILED:
            return {
                ...state,
                error: action.payload.error
            };
        case ADD_ROLE_SUCCESS:
            return {
                ...state,
                error: null
            };
        case ADD_ROLE_FAILED:
            return {
                ...state,
                error: action.payload.error
            };
        default:
            return state;
    }
};