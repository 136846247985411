import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getUsers
} from 'actions/userActions';
import { userSelector } from 'selectors/userSelector';

export function useUsers(filters) {
  const dispatch = useDispatch();
  const {
    isLoading,
    total,
    users,
    usersColumns,
    usersCurrentPage,
    usersLastPage,
    usersError
  } = useSelector(userSelector);

  useEffect(() => {
    dispatch(getUsers(filters));
  }, [dispatch, filters]);

  return {
    isLoading,
    total,
    users,
    usersColumns,
    usersCurrentPage,
    usersLastPage,
    usersError
  };
};