import "./AlertMessage.css";
import { Alert } from "react-bootstrap";

function AlertMessage(props) {
    const { heading, message, visibility, onClose, variant, dismissible } = props;

    return(
        <Alert className="alert-message" variant={variant} dismissible={dismissible} show={visibility} onClose={onClose}>
            <Alert.Heading>{heading}</Alert.Heading>
            <p>
                {message}
            </p>
        </Alert>
    )
}

export default AlertMessage;