import { Spinner } from 'react-bootstrap';

const LoadingOverlay = ({ show, message = 'Saving...' }) => {
  if (!show) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
      }}
    >
      <Spinner animation="border" variant="light" />
      <div className="text-light mt-2">{message}</div>
    </div>
  );
};

export default LoadingOverlay;