import getURL from "api/config";
import { POST } from "api/index";
import * as types from "constants/actionTypes";

export const sendRequestForDemoEmailAction = (formData) => async(dispatch) => {
    const content = {
        from: {
            email: process.env.REACT_APP_SENDGRID_FROM_EMAIL,
            name: process.env.REACT_APP_SENDGRID_FROM_NAME
        },
        to: [
            {
                email: process.env.REACT_APP_DEMO_EMAIL
            }
        ],
        subject: "Request for Demo",
        content: [
            {
                type: "text/plain",
                value: `Name: ${formData.firstName} ${formData.lastName}\nEmail: ${formData.email}\nMobile Number: ${formData.mobileNumber}\nFacebook: ${formData.facebook}`
            }
        ]
    };

    try {
        const url = getURL("SEND_REQUEST_FOR_DEMO_EMAIL");
        const response = await POST(url, content, {
            headers: {
                "Authorization": `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`,
                "Content-Type": "application/json"
            }
        });
        
        dispatch({  
            type: types.SEND_REQUEST_FOR_DEMO_EMAIL_SUCCESS,
            payload: { requestForDemoEmailStatus: response.status }
        });
    } catch (error) {
        dispatch({
            type: types.SEND_REQUEST_FOR_DEMO_EMAIL_FAILED,
            payload: { error: error, status: error.status }
        });
    };
};