import getURL from "api/config";
import { PUT, POST } from "api/index";
import * as types from "constants/actionTypes";
import { getError } from "helpers";
export function addVariableOptionAction(data) {
    return function action(dispatch) {
        const url = getURL("ADD_VARIABLE_OPTION");
        const request = POST(url, data);

        request.then((response) => {
            dispatch({
                type: types.ADD_VARIABLE_OPTION_SUCCESS,
                payload: { variableOption: response.data.result }
            })
        }, (error) => {
            dispatch({
                type: types.ADD_VARIABLE_OPTION_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        });
    };
};

export function updateVariableOptionAction(id, data) {
    return function action(dispatch) {
        const url = getURL("UPDATE_VARIABLE_OPTION")(id);
        const request = PUT(url, data);

        request.then((response) => {
            dispatch({
                type: types.UPDATE_VARIABLE_OPTION_SUCCESS,
                payload: { variableOption: response.data.result }
            })
        }, (error) => {
            dispatch({
                type: types.UPDATE_VARIABLE_OPTION_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        });
    };
};