import { Outlet, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Button, Card, Container, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRotateLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const KpiLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const getPageTitle = () => {
    if (location.pathname.endsWith('/new')) {
      return 'Create KPI';
    } else if (id) {
      return 'Edit KPI';
    }
    return 'KPI List';
  };

  const showActions = () => {
    return location.pathname === '/cms/kpis';
  };

  const handleRefresh = () => {
    // If we're on the list page, reload the current route
    if (showActions()) {
      navigate(0); // This is equivalent to window.location.reload()
    }
  };

  return (
    <section id='kpi-container'>
      <Container fluid>
        <Card>
          <Card.Header>
            <Stack direction='horizontal' gap={2} className='d-flex justify-content-between'>
              <Stack direction='horizontal' gap={3}>
                {!showActions() ? (
                  <>
                    <Link to='/cms/kpis' className='nav-link'>KPI List</Link>
                    <FontAwesomeIcon icon={faChevronRight} />
                    <span>{getPageTitle()}</span>
                  </>
                ) : (
                  <div className='mb-0'>{getPageTitle()}</div>
                )}
              </Stack>
              {showActions() && (
                <div>
                  <Link to='new' className='btn btn-sm btn-success'>
                    <FontAwesomeIcon icon={faPlus} />
                  </Link>
                  {' '}
                  <Button variant='outline-primary' size='sm' onClick={handleRefresh} title='Refresh List'>
                    <FontAwesomeIcon icon={faRotateLeft} />
                  </Button>
                </div>
              )}
            </Stack>
          </Card.Header>
          <Card.Body className='wrapper'>
            <Outlet />
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};

export default KpiLayout;