import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getVariableListAction
} from 'actions/variableActions';
import { variableSelector } from 'selectors/variableSelector';

export function useVariables(filters) {
  const dispatch = useDispatch();
  const {
    variables,
    variablesError
  } = useSelector(variableSelector);

  useEffect(() => {
    dispatch(getVariableListAction(filters));
  }, [dispatch, filters]);

  return {
    variables,
    variablesError
  };
};