import Immutable from "seamless-immutable";

const initialState = Immutable({
    showSidebar: true
})

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case 'TOGGLE_SIDEBAR':
            return {
                ...state,
                showSidebar: !state.showSidebar
            };
        default:
            return state;
    }
};